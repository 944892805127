import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import HomeLanding from "./pages/HomeLanding/HomeLanding";
import MouLanding from "./pages/MouLanding/MouLanding";
import RefinaryLanding from "./pages/RefinaryLanding/RefinaryLanding";
import ITRMLanding from "./pages/ItrmLanding/ItrmLanding";
import BuLanding from "./pages/BuLanding/BuLanding";
import OrgLanding from "./pages/OrgLanding/OrgLanding";
import Login from "./components/Authentication/components/Login";
import Register from "./components/Authentication/components/Register";
import Backdashboard from "./pages/BackDashboard/Backdashboard";
import Adminlogin from "./pages/BackDashboard/Adminlogin";
import ApplicationContext, { initialContext } from "./ApplicationContext";
import {
  getLoggedInUser,
  persistLoggedInUser,
  clearPersistedLoggedInUser,
  getCurrentUser,
} from "./services/auth";
import { ProtectedRoute } from "./utility/ProtectedRoute";
import * as Roles from "./utility/Roles";
import { NotFound } from "./pages/NotFound/NotFound";
import HealthCheck from "./pages/HealthCheck";
import QRScanner from "./pages/QRScanner/QRScanner";
import EventUserList from "./pages/QRScanner/userList";
import WinnerList from "./pages/QRScanner/winnerList";
import { SnackbarProvider } from "notistack";
import EventLogin from "./pages/QRScanner/Login/login";
import { ProtectedEventRoute } from "./pages/QRScanner/Routing/ProtectedEventRoute";

const App = () => {
  const [applicationState, setApplicationState] = useState(initialContext);
  const [isApplicationReady, setIsApplicationReady] = useState(false);

  const setLoggedInUser = (user) => {
    setApplicationState((prev) => ({
      ...prev,
      loggedInUser: user,
    }));

    if (user) {
      persistLoggedInUser(user);
    } else {
      clearPersistedLoggedInUser();
    }
  };

  const authFromPersistence = async () => {
    try {
      const persistedUser = getLoggedInUser();
      if (persistedUser) {
        const loggedInUser = await getCurrentUser();
        loggedInUser.accessToken = persistedUser.accessToken;
        setLoggedInUser(loggedInUser);
        return;
      }
    } catch (error) {
      console.warn("Invalid user data");
    }
  };

  useEffect(() => {
    const timeInterval = setInterval(() => {
      let reftime = new Date().getHours() + ":" + new Date().getMinutes();
      if (reftime >= 10 + ":" + 0 && reftime < 10 + ":" + 1) {
        window.location.reload();
      }
    }, 60 * 1000);
    authFromPersistence()
      .then(() => setIsApplicationReady(true))
      .catch(() => setIsApplicationReady(true));
    return () => clearInterval(timeInterval);
  }, []);

  const contextValue = {
    ...applicationState,
    setLoggedInUser,
  };

  if (!isApplicationReady) {
    return (
      <div className="progress">
        <div
          className="progress-bar progress-bar-striped progress-bar-animated"
          role="progressbar"
          aria-valuenow="100"
          aria-valuemin="0"
          aria-valuemax="100"
          style={{
            width: "100%",
          }}
        ></div>
      </div>
    );
  }

  return (
    <ApplicationContext.Provider value={contextValue}>
      <Router>
        <Switch>
          <Route exact path="/" component={HomeLanding} />
          <Route exact path="/login" component={Login} />

          {/* <Route exact path='/register' component={Register} /> */}
          <ProtectedRoute exact path="/itrm" component={ITRMLanding} />
          <ProtectedRoute exact path="/mou" component={MouLanding} />
          <ProtectedRoute exact path="/refinery" component={RefinaryLanding} />
          <ProtectedRoute exact path="/bu" component={BuLanding} />
          <ProtectedRoute exact path="/org" component={OrgLanding} />
          <Route exact path="/adminlogin" component={Adminlogin} />
          <Route exact path="/chkreactlive" component={HealthCheck} />
          <ProtectedRoute
            role={Roles.ADMIN}
            exact
            path="/bpcladmin"
            component={Backdashboard}
          />
          {/* QR code scanner Routing */}
          <SnackbarProvider maxSnack={3}>
            <Route exact path="/event/login" component={EventLogin} />
            <ProtectedEventRoute
              exact
              path="/event/qr-scanner"
              component={QRScanner}
            />
            <ProtectedEventRoute
              exact
              path="/event/user-list"
              component={EventUserList}
            />
            <ProtectedEventRoute
              exact
              path="/event/winner-list"
              component={WinnerList}
            />
          </SnackbarProvider>
          <Route path="*" component={NotFound} />
        </Switch>
      </Router>
    </ApplicationContext.Provider>
  );
};

export default App;
