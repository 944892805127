import { Route, useHistory } from "react-router-dom";
import React, { useEffect } from "react";

export const ProtectedEventRoute = ({ ...props }) => {
  const isAuthenticated = !!localStorage.getItem("token");
  const history = useHistory();

  useEffect(() => {
    if (!isAuthenticated) {
      history.push(`/event/login`);
    }
  }, [isAuthenticated, history]);

  if (isAuthenticated) {
    return <Route {...props} />;
  }

  return null;
};
