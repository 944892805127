import React, { useRef, useEffect, useState } from 'react';
import Chart from 'chart.js';
import 'chartjs-plugin-datalabels';
import { numberFormatNoSeparator } from '../../utility/utility';
import styles from './topCountry.module.scss';

Chart.defaults.global.defaultFontFamily = 'Titillium Web';

const TopCountry = (props) => {
    const chartContainer = useRef('barGraph');
    const [chartInstance, setChartInstance] = useState(null);

    useEffect(() => {
        let incidentData = ["41.19","34.12","14,33","9.47","0.90"] 

        let xTicks = ['Saudi Arabia', 'Russia', 'UAE', 'Iraq', 'Kuwait'];
        
        let incidentDataMumbai = [32.3, 22.0, 19.9, 15.2, 10.6]   
//    20.3
let incidentDataKochi = [58.4, 17.3, 15.6,7.2,1.4] 
        //  9.7
        let incidentDataBina = [41.3, 34.1, 14.3,9.5,0.9] 
        // 4.5
        if (chartInstance) {
            chartInstance.destroy();
        }

       
        incidentData = incidentData.reduce((acc, val, index) => {
            acc[xTicks[index]] = val; 
            return acc;
        }, {});

        const ctx = chartContainer.current.getContext('2d');
        let gradient = ctx.createLinearGradient(0, 0, 0, 450);
        gradient.addColorStop(0, '#f1c40f')
        gradient.addColorStop(1, '#d4ac0d')
        const footer = (tooltipItems) => {
            let sum = 0;
          
            tooltipItems.forEach(function(tooltipItem) {
              sum += tooltipItem.parsed.y;
            });
            return 'Sum: ' + sum;
          };
        const chartConfig = {
            type: 'bar',
            data: {
                labels: xTicks,
                datasets: [
                    {
                        // label: 'Incident reported',
                        data: ["41.19","34.12","14.33","9.47","0.90"],
                        categoryPercentage: 0.8,
                        // barPercentage: 0.5,
                        backgroundColor: gradient,
                        borderWidth: 0,
                        datalabels: {
                            display: true,
                            anchor: 'end',
                            align: 'end',
                            color: '#ffffff',
                            font: { size: 14 },
                            formatter: function (value) {
                                return value !== 0 ? `${numberFormatNoSeparator(value)} %` : '';
                            }
                        },
                        order: 2
                    }
                ]
            },
            options: {
                layout: {
                    padding: {
                        left: 0,
                        right: 0,
                        top: 30,
                        bottom: 0
                    }
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    xAxes: [
                        {
                            stacked: true,
                            type: 'category',
                            display: true,
                            offset: true,
                            barPercentage: 0.4,
                            gridLines: {
                                color: 'transparent',
                                offsetGridLines: true,
                                tickMarkLength: 0,
                                // borderDash: [3],
                                // lineWidth: 3,
                                // zeroLineBorderDash: [3]
                            },
                            ticks: {
                                display: true,
                                padding: 9,
                                fontColor: '#dee5ef',
                                fontSize: '15',
                            },
                        },
                    ],

                    // yAxes: [
                    //     {
                    //         stacked: true,
                    //         ticks: {
                    //             display: true,
                    //             padding: 20,
                    //             fontColor: '#dee5ef',
                    //             fontSize: '20',
                    //             maxTicksLimit: 5
                    //         },
                    //         gridLines: {
                    //             color: '#2f3b51',
                    //             // tickMarkLength: 0,
                    //             zeroLineColor: '#67758d',
                    //             zeroLineWidth: 1,
                    //             lineWidth: 3,
                    //         },
                    //         scaleLabel: {
                    //             display: true,
                    //             fontColor: '#dee5ef',
                    //             fontSize: '20',
                    //         },
                    //     },
                    // ]
                },
                legend: {
                    display: false
                },
                plugins: {
                    tooltip: {
                      callbacks: {
                        footer: footer,
                      }
                    }
                  }
            }
        };

        // Create the chart instance
        if (chartContainer && chartContainer.current) {
            const newChartInstance = new Chart(ctx, chartConfig);
            setChartInstance(newChartInstance);
        }
    }, [props.graphData, props.publicVar, props.privateVar, props.bothVar, props.hsseData]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`flex-grow-1 ${styles.graphContainer}`}>
            <div className={`d-flex justify-content-between align-items-center ${styles.legendsContainer}`}>
                <div className={`d-flex align-items-center justify-content-around p-2 ${styles.tabLabelsContainer}`}>
                    <div className="d-flex align-items-center">
                    Top 5 Crude Suppliers to BPCL (4.46 MMT)
                    </div>
                </div>
            </div>
            <div className={`my-4 ${styles.grmGraph}`}>
                <canvas ref={chartContainer} />
            </div>
            <div className={`d-flex align-items-start ${styles.cduContainer}`}>
                <div className={`${styles.cduLeft}`}>&nbsp;&nbsp;Total Crude Suppliers &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                <div className={`d-flex justify-content-center align-items-start ${styles.cduRight}`} style={{ color: `${props.textColor}` }} >5</div>
            </div>
        </div>
    );
};

export default TopCountry;
