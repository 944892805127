import React, { useEffect, useRef, useState } from "react";
import jsQR from "jsqr";
import { checkinVisitors } from "../QRScanner/services/qrService";
import { enqueueSnackbar } from "notistack";
import logo from "../../assets/images/event/logo.jpg";
import { useHistory } from "react-router-dom";

const QRScanner = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [visitorData, setVisitorData] = useState(null);
  const [scanning, setScanning] = useState(false);
  const [qrLoading, setQRLoading] = useState(true);
  const [message, setMessage] = useState("");
  const streamRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    return () => {
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }
    };
  }, []);

  const startScanning = () => {
    setMessage("Scanning... Please wait");
    setQRLoading(true);
    setVisitorData(null);

    navigator.mediaDevices
      .getUserMedia({ video: { facingMode: "environment" } })
      .then((userMediaStream) => {
        if (videoRef.current) {
          streamRef.current = userMediaStream;
          videoRef.current.srcObject = streamRef.current;
          videoRef.current.setAttribute("playsinline", true);

          videoRef.current.onloadedmetadata = () => {
            videoRef.current.play();
            setQRLoading(false);
            setScanning(true);
            setMessage("Scanning... Please wait");

            if (canvasRef.current) {
              const canvas = canvasRef.current;
              const context = canvas.getContext("2d");
              canvas.width = videoRef.current.videoWidth;
              canvas.height = videoRef.current.videoHeight;
            }

            requestAnimationFrame(scanQRCode);
          };
        }
      })
      .catch((err) => {
        setQRLoading(false);
        setMessage("Failed to access camera");
      });
  };

  const stopScanning = () => {
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    setScanning(false);
    if (canvasRef.current) {
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height);
    }
  };

  const scanQRCode = () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext("2d");

    if (
      !videoRef.current ||
      !videoRef.current.videoWidth ||
      !videoRef.current.videoHeight
    ) {
      requestAnimationFrame(scanQRCode);
      return;
    }

    canvas.width = videoRef.current.videoWidth;
    canvas.height = videoRef.current.videoHeight;

    context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);

    const imageData = context.getImageData(0, 0, canvas.width, canvas.height);
    const code = jsQR(imageData.data, canvas.width, canvas.height);

    if (code) {
      const data = decodeQRData(code.data);
      if (data) {
        if (data.type === "url") {
          sendCheckInDataToServer(data);
        } else if (data.type === "text") {
          sendCheckInDataToServer(data);
        } else {
          sendCheckInDataToServer(data);
        }
        setMessage("Scan completed!");
        stopScanning();
      }
    } else {
      requestAnimationFrame(scanQRCode);
    }
  };

  const decodeQRData = (data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      if (data.startsWith("http://") || data.startsWith("https://")) {
        return { type: "url", url: data };
      } else {
        const parts = data.split(",").map((part) => part.trim());

        if (parts.length >= 5) {
          const id = parts[0];
          const name = parts.slice(1, parts.length - 3).join(" ");
          const company_name = parts[parts.length - 3];
          const pavillion_details = parts[parts.length - 2];
          const seat = parts[parts.length - 1];

          return {
            type: "visitor",
            id,
            name,
            company_name,
            pavillion_details,
            seat,
          };
        } else {
          return { type: "text", text: data };
        }
      }
    }
  };

  const displayVisitorDetails = (data) => {
    setVisitorData(data);
    setMessage("Scan completed!");
  };

  const sendCheckInDataToServer = async (visitorData) => {
    try {
      const response = await checkinVisitors({
        reference_number: visitorData.id,
      });
      const data = response.data;
      if (data.status === "success" && data.statusCode === 200) {
        displayVisitorDetails(visitorData);
        enqueueSnackbar("Checkin successfully", {
          variant: "success",
        });
      } else {
        throw new Error(response?.message || "Unexpected error occurred");
      }
    } catch (error) {
      setVisitorData(null);
      enqueueSnackbar(
        error?.response?.data?.message || "Something went wrong",
        {
          variant: "error",
        }
      );
    }
  };

  const handleUserlist = () => {
    history.push("/event/user-list");
  };
  const handleWinnerlist = () => {
    history.push("/event/winner-list");
  };
  return (
    <div>
      <header
        style={{
          backgroundColor: "#fff",
          padding: "10px 20px",
          textAlign: "left",
          margin: 0,
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: "80px", marginRight: "10px" }}
        />
      </header>

      <header className="user-list-header">
        <h1>QR Code Scanner</h1>

        <nav className="ctm-nav-bar">
          <button onClick={() => handleUserlist()} className="navBar-btn-1">
            User List
          </button>
          <button onClick={() => handleWinnerlist()} className="navBar-btn-1">
            Winner List
          </button>
        </nav>
      </header>

      <div className="qr-code-event">
        <h2>{message}</h2>

        <div>
          {!visitorData && (
            <div
              style={{
                display: qrLoading ? "none" : "inline-block",
                border: "3px solid #ccc",
                padding: "10px",
                borderRadius: "8px",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <video
                ref={videoRef}
                width="320"
                height="240"
                autoPlay
                style={{
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  display: qrLoading ? "none" : "block",
                }}
              ></video>

              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  width: "90%",
                  height: "60%",
                  border: "2px dashed #ff0000",
                  pointerEvents: "none",
                }}
              />
            </div>
          )}

          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>

          <div style={{ marginTop: "20px" }}>
            {!scanning ? (
              <button
                onClick={startScanning}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#4CAF50",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Start Scanning
              </button>
            ) : (
              <button
                onClick={stopScanning}
                style={{
                  padding: "10px 20px",
                  fontSize: "16px",
                  backgroundColor: "#f44336",
                  color: "white",
                  border: "none",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Stop Scanning
              </button>
            )}
          </div>

          {visitorData && (
            <div id="visitor-info" style={{ marginTop: "20px" }}>
              <p
                id="visitor-name"
                style={{ fontSize: "1.5em", fontWeight: "bold" }}
              >
                Welcome, {visitorData.name}
              </p>
              <table
                style={{
                  width: "60%",
                  borderCollapse: "collapse",
                  marginTop: "20px",
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
              >
                <thead>
                  <tr>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      Company Name
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      Pavilion Details
                    </th>
                    <th
                      style={{
                        padding: "10px",
                        border: "1px solid #ddd",
                        textAlign: "center",
                      }}
                    >
                      Seat Number
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {visitorData.company_name}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {visitorData.pavillion_details}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {visitorData.seat}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QRScanner;
