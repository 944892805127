import React, { useEffect, useState } from "react";
import { getUserOfEventExport } from "../QRScanner/services/qrService";
import { enqueueSnackbar } from "notistack";
import logo from "../../assets/images/event/logo.jpg";
import DataTable from "react-data-table-component";
import "./utils/common/qr.scss";
import { useHistory } from "react-router-dom";

const EventUserList = () => {
  const [userData, setUserData] = useState([]);
  const [pageLimit, setPageLimit] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [sortDirection, setSortDirection] = useState("");
  const [sortColumn, setSortColumn] = useState("");
  const [loading, setLoading] = useState(true);
  const history = useHistory();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await getUserOfEventExport({
        page: 1,
        limit: 1000,
        orderBy: "ASC",
        orderName: "user_name",
        search: "",
        all_records: false,
      });
      const data = response.data;
      if (
        data.status === "success" &&
        data.statusCode === 200 &&
        data.data?.users_data?.length > 0
      ) {
        setUserData(data.data.users_data);
      } else {
        setUserData([]);
        throw new Error(response?.message || "Something went wrong");
      }
    } catch (error) {
      enqueueSnackbar(error?.message || "Something went wrong", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const handleLimitChange = (e) => {
    setPageLimit(e);
  };

  const handlePageChange = (e) => {
    setPageNum(e);
  };

  const handleSort = (column, sortDirection) => {
    if (column && column.key) {
      if (column.sortFunction) {
        setSortColumn(column.sortKey);
        setSortDirection(sortDirection);
        setUserData((prevData) => {
          const sortedData = [...prevData].sort(
            (a, b) =>
              column.sortFunction(a, b) * (sortDirection === "asc" ? 1 : -1)
          );
          return sortedData;
        });
      } else {
        setSortColumn(column.key);
        setSortDirection(sortDirection);
      }
    }
  };

  const handleQRScanner = () => {
    history.push("/event/qr-scanner");
  };

  const handleWinnerlist = () => {
    history.push("/event/winner-list");
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const columns = [
    {
      name: "S.No",
      cell: (row, index) => index + 1 + (pageNum - 1) * pageLimit,
      width: "60px",
    },
    {
      name: "QR Code",
      cell: (row, index) => (
        <img
          src={row.qr_code || ""}
          alt="Image"
          style={{ width: "80px", height: "auto" }}
        />
      ),
      width: "100px",
    },
    {
      name: "Name",
      selector: (row) => row.user_name,
      sortable: true,
      key: "user_name",
      width: "100px",
    },
    {
      name: "Company Name",
      selector: (row) => row.company_name,
      sortable: true,
      key: "company_name",
      width: "90px",
      className: "company-name",
    },
    {
      name: "Address",
      selector: (row) => row.user_address,
      sortable: true,
      key: "user_address",
      width: "92px",
      className: "address",
    },
    {
      name: "Phone Number",
      selector: (row) => {
        const phone = row.phone ? String(row.phone) : "";
        if (phone.length > 5) {
          return `*****${phone.slice(-5)}`;
        }
        return phone;
      },
      sortable: false,
      key: "phone",
      width: "90px",
    },
    {
      name: "Pav. Details",
      selector: (row) => row.pavillion_details,
      sortable: true,
      key: "pavillion_details",
      width: "85px",
    },
    {
      name: "Seat No.",
      selector: (row) => (row.seat_no ? Number(row.seat_no) : ""),
      sortable: true,
      key: "seat_no",
      width: "68px",
    },
    {
      name: "Check In",
      selector: (row) => (row.checked_in === 0 ? "No" : "Yes"),
      sortable: true,
      key: "checked_in",
      width: "80px",
    },
    {
      name: "Check In Date",
      selector: (row) =>
        row.checkin_date != null ? formatDate(row.checkin_date) : "",
      sortable: true,
      key: "checkin_date",
    },
  ];

  const customStyles = {
    cells: {
      style: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "center",
      },
    },
    headCells: {
      style: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "center",
        fontWeight: "bold",
        display: "block",
        whiteSpace: "normal",
        wordWrap: "break-word",
      },
    },
    table: {
      style: {
        width: "100%",
      },
    },
  };

  const conditionalRowStyles = [
    {
      when: (row) => row.checked_in === 1,
      style: {
        backgroundColor: "#d4edda",
        color: "#155724",
      },
    },
  ];

  return (
    <div>
      <header
        style={{
          backgroundColor: "#fff",
          padding: "10px 20px",
          textAlign: "left",
          margin: 0,
        }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: "80px", marginRight: "10px" }}
        />
      </header>

      <header className="user-list-header">
        <h1>Event User List</h1>

        <nav className="ctm-nav-bar">
          <button onClick={() => handleQRScanner()} className="navBar-btn-1">
            QR Code Scanner
          </button>
          <button onClick={() => handleWinnerlist()} className="navBar-btn-2">
            Winner List
          </button>
        </nav>
      </header>

      <div className="event-user-list">
        {loading ? (
          <div className="loading-spinner">
            <div className="spinner"></div>
          </div>
        ) : (
          <DataTable
            columns={columns}
            data={userData}
            onChangePage={handlePageChange}
            onChangeRowsPerPage={handleLimitChange}
            onSort={handleSort}
            customStyles={customStyles}
            conditionalRowStyles={conditionalRowStyles}
            className="omni-datatable"
          />
        )}
      </div>
    </div>
  );
};

export default EventUserList;
