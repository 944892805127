import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import styles from './RefinaryInfographic.module.scss'
//import mumbaiInfographic from "../../../assets/images/mumbaiInfographic.png";
//import kochiInfographic from "../../../assets/images/kochiInfographic.png";
import InfographicKpi1 from '../InfographicKpi1/InfographicKpi1'
import InfographicKpi2 from '../InfographicKpi2/InfographicKpi2'
//import InfographicKpi3 from "../InfographicKpi3/InfographicKpi3";
//import InfographicKpi4 from "../InfographicKpi4/InfographicKpi4";
import InfographicKpi5 from '../InfographicKpi5/InfographicKpi5'
import InfographicKpiRound from '../InfographicKpiRound/InfographicKpiRound'
import { fetchMumbaiProductInv, fetchMumbaiUnitWiseUtil, fetchTotalCrudeKochiMtdCrudeOilInventory, fetchTotalCrudeKochiYtmCrudeOilInventory, fetchupdatedStatus } from '../../../services/Refinery'
import mumbaiVideo from '../../../assets/images/Mumbai Refinery_final.m4v'
import kochiVideo from '../../../assets/images/Kochin Refinery_final.m4v'
import greenTick from '../../../assets/images/greenTick.png'
import { isEmpty } from 'lodash'
import "../../../Common.scss"

const RefinaryInfographic = (props) => {
    const [utilData, setUtilData] = useState({})
    const [updatedDate, setUpdatedDate] = useState([])
    const [graphData, setGraphData] = useState([])
    const [inventoryData, setInventoryData] = useState({})
    const [crudeProcYtmData, setCrudeProcYtmData] = useState({})
    const [crudeProcMtdData, setCrudeProcMtdData] = useState({})
    const [textColor, setTextColor] = useState('#06bee1')
    const [updatedStatusUnitsCapacityUtilization, setUpdatedStatusUnitsCapacityUtilization] = useState('')
    const [updatedStatusCrudeOilProcessing, setUpdatedStatusCrudeOilProcessing] = useState('')
    const [updatedStatusProductInventory, setUpdatedStatusProductInventory] = useState('')
    useEffect(() => {
        const staticLocation = props.location === "mumbai" ? "Mumbai" : props.location === "kochi" ? "Kochi" : "Bina"
        fetchupdatedStatus(props.year)
        .then(response => {
            if (!isEmpty(response)) {
                response.filter((res) => res.entity === staticLocation && res.kpi_name === "Units Capacity Utilization" ? setUpdatedStatusUnitsCapacityUtilization(res.status) : false);
                response.filter((res) => res.entity === staticLocation && res.kpi_name === "Crude Oil Processing" ? setUpdatedStatusCrudeOilProcessing(res.status) : false);
                response.filter((res) => res.entity === staticLocation && res.kpi_name === "Product Inventory" ? setUpdatedStatusProductInventory(res.status) : false);
            } else {
                setUpdatedStatusUnitsCapacityUtilization("Not Found")
                setUpdatedStatusCrudeOilProcessing("Not Found")
                setUpdatedStatusProductInventory("Not Found")
            }
        })
        .catch(error => console.debug(error))
        fetchTotalCrudeKochiMtdCrudeOilInventory(props.year, props.location)
            .then((response) => {
                // console.debug("crudeProcessedKochiMtd => ", response.data);
                if (props.location === 'kochi') {
                    setTextColor('#b76df1')
                } else if(props.location === 'mumbai') {
                    setTextColor('#06bee1')
                } else {
                    setTextColor('#99eb6a')
                }
                let dictionary = {}
                for (let i of response.data) {
                    if (!dictionary[i.product]) {
                        if (dictionary[i.product] !== 0) {
                            dictionary[i.product] = i.actual
                            dictionary['deltaMtd' + i.product] = i.delta
                        }
                    }

                    setCrudeProcMtdData(dictionary)
                }
            })
            .catch((error) => console.debug(error))

        fetchTotalCrudeKochiYtmCrudeOilInventory(props.year, props.location)
            .then((response) => {
                // console.debug("crudeProcessedKochiYtm => ", response.data);
                if (props.location === 'kochi') {
                    setTextColor('#b76df1')
                } else if(props.location === 'mumbai') {
                    setTextColor('#06bee1')
                } else {
                    setTextColor('#99eb6a')
                }
                let dictionary = {}
                for (let i of response.data) {
                    if (!dictionary[i.product]) {
                        if (dictionary[i.product] !== 0) {
                            dictionary[i.product] = i.actual
                            dictionary['deltaYtm' + i.product] = i.delta
                        }
                    }
                }
                setCrudeProcYtmData(dictionary)
            })
            .catch((error) => console.debug(error))

        fetchMumbaiUnitWiseUtil(props.year, props.location)
            .then((response) => {
                // console.debug("mumbaiUnitWiseUtilData => ", response.data[0].updatedAt);
                setUpdatedDate(response.data)
                if (props.location === 'kochi') {
                    setTextColor('#b76df1')
                } else if(props.location === 'mumbai') {
                    setTextColor('#06bee1')
                } else {
                    setTextColor('#99eb6a')
                }

                let dictionary = {}
                for (let i of response.data) {
                    if (!dictionary[i.product]) {
                        if (dictionary[i.product] !== 0) {
                            dictionary[i.product] = i.actual
                        }
                    }
                }
                setUtilData(dictionary)
            })
            .catch((error) => console.debug(error))

        fetchMumbaiProductInv(props.year, props.location)
            .then((response) => {
                // console.debug("mumbaiProductInvData => ", response.data);
                if (props.location === 'kochi') {
                    setTextColor('#b76df1')
                } else if(props.location === 'mumbai') {
                    setTextColor('#06bee1')
                } else {
                    setTextColor('#99eb6a')
                }
                let dictionary = {}
                for (let i of response.data) {
                    if (!dictionary[i.product]) {
                        if (dictionary[i.product] !== 0) {
                            dictionary[i.product] = i.actual
                        }
                    }
                }
                setInventoryData(dictionary)
            })
            .catch((error) => console.debug(error))
    }, [props.location, props.year])

    useEffect(() => {
        function switchLocations() {
            if (props.location === 'mumbai') {
                setGraphData(updatedDate)
            } else {
                setGraphData(updatedDate)
            }
        }
        switchLocations()
    }, [props.location, updatedDate, props.year])


    return (
        <div className={`${styles.InfographicContainer}`}>
            {/* <img src={mumbaiInfographic} alt='Refinery Diagram'></img> */}
            {props.location === 'mumbai' ? (
                <video
                    style={{
                        position: 'relative',
                        left: '-35px',
                        bottom: '-15px',
                    }}
                    autoPlay
                    loop
                    src={mumbaiVideo}
                ></video>
            ) : (
                <video
                    style={{
                        position: 'relative',
                        left: '-35px',
                        bottom: '-15px',
                    }}
                    autoPlay
                    loop
                    src={kochiVideo}
                ></video>
            )}

            {!utilData && !inventoryData && crudeProcMtdData && crudeProcYtmData ? (
                <div className="d-flex justify-content-center align-items-center">
                    <Spinner animation="border" role="status">
                        <span className="sr-only">Loading...</span>
                    </Spinner>
                </div>
            ) : (
                <>
                    <div className={`${styles.tcheading}`}>
                            <p>Crude Oil Processing (TMT) - Daily</p>
                            {(updatedStatusCrudeOilProcessing === "Updated") && <div className={`${styles.statusUpdatedImg1}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                    </div>
                    <div className={`${styles.totalCrude}`}>
                        <p>Total Crude</p>
                    </div>
                    <div className={`${styles.uwuheading}`}>
                        <p>Units Capacity Utilization (%) - Twice Daily</p>
                       {(updatedStatusUnitsCapacityUtilization === "Updated") && <div className={`${styles.statusUpdatedImg2}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                    </div>
                    {/* <div className={`${styles.uwuheadingpi}`}>
                        <p>Product Inventory (TMT/TKL)</p>
                    </div> */}
                     <div className={`${styles.uwuheadingpi}`}>
                        <p>Product Inventory (TMT) - Twice Daily</p>
                            {(updatedStatusProductInventory === "Updated") && <div className={`${styles.statusUpdatedImg3}`}>
                                <img src={greenTick} className="greenTik" alt='Expand Icon'></img>
                            </div>}
                    </div>
                    {  props.location === 'bina' ?
                     <><div className={`${styles.kochiVid}`}>
                                <p> </p>
                            </div><div className={`${styles.kochiVid1}`}>
                                    <p> </p>
                                </div><div className={`${styles.kochiVid2}`}>
                                    <p> </p>
                                </div>
                                <div className={`${styles.kochiVid3}`}>
                                    <p> </p>
                                </div>
                                <div className={`${styles.kochiVid4}`}>
                                    <p> </p>
                                </div>
                                <div className={`${styles.kochiVid5}`}>
                                    <p> </p>
                                </div>
                                </> : 
                                <div className={`${styles.kochiVid0}`}>
                                    <p> </p>
                                </div> 
            }
                    <div className={`${styles.updatedDate}`}>
                        {/* {graphData != undefined && graphData.length > 0 ? <>Last Updated on {new Date(graphData[0].entry_date).toLocaleDateString("en-GB")}</> : <>No Data Avaliable</>} */}
                    </div>
                    {utilData['ARU'] ? (
                        <InfographicKpiRound
                            top="146px"
                            right="1244px"
                            bottom=""
                            left=""
                            kpiName="ARU"
                            textColor={textColor}
                            kpiValue={parseFloat(utilData['ARU']).toFixed(1)}
                        ></InfographicKpiRound>
                    ) : (
                        <InfographicKpiRound
                            top="146px"
                            right="1244px"
                            bottom=""
                            left=""
                            kpiName="ARU"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpiRound>
                    )}
                    {utilData['CDU III']
                        ? props.location === 'mumbai' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="956px"
                                kpiName="CDU III"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['CDU III']).toFixed(1)}
                            ></InfographicKpi1>
                        )
                        : props.location === 'mumbai' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="956px"
                                kpiName="CDU III"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi1>
                        )}
                    {utilData['CDU IV']
                        ? props.location === 'mumbai' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="1165px"
                                kpiName="CDU IV"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['CDU IV']).toFixed(1)}
                            ></InfographicKpi1>
                        )
                        : props.location === 'mumbai' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="1165px"
                                kpiName="CDU IV"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi1>
                        )}
                    {utilData['CDU II']
                        ? props.location === 'kochi' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="956px"
                                kpiName="CDU II"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['CDU II']).toFixed(1)}
                            ></InfographicKpi1>
                        )
                        : props.location === 'kochi' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="956px"
                                kpiName="CDU II"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi1>
                        )}
                    {utilData['CDU']
                        ? props.location === 'bina' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="956px"
                                kpiName="CDU"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['CDU']).toFixed(1)}
                            ></InfographicKpi1>
                        )
                        : props.location === 'bina' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="956px"
                                kpiName="CDU"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi1>
                        )}
                    {utilData['CDU III']
                        ? props.location === 'kochi' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="1165px"
                                kpiName="CDU III"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['CDU III']).toFixed(1)}
                            ></InfographicKpi1>
                        )
                        : props.location === 'kochi' && (
                            <InfographicKpi1
                                top="46px"
                                right=""
                                bottom=""
                                left="1165px"
                                kpiName="CDU III"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi1>
                        )}
                    {utilData['NHT']
                        ? props.location === 'mumbai' && (
                            <InfographicKpi2
                                top="238px"
                                right=""
                                bottom=""
                                left="1040px"
                                kpiName="NHT"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['NHT']).toFixed(1)}
                            ></InfographicKpi2>
                        )
                        : props.location === 'mumbai' && (
                            <InfographicKpi2
                                top="238px"
                                right=""
                                bottom=""
                                left="1040px"
                                kpiName="NHT"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi2>
                        )}
                    {utilData['NHT ISOM']
                        ? props.location === 'mumbai' && (
                            <InfographicKpi2
                                top="313px"
                                right=""
                                bottom=""
                                left="1040px"
                                kpiName="NHT"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['NHT ISOM']).toFixed(1)}
                            ></InfographicKpi2>
                        )
                        : props.location === 'mumbai' && (
                            <InfographicKpi2
                                top="313px"
                                right=""
                                bottom=""
                                left="1040px"
                                kpiName="NHT"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi2>
                        )}
                    {utilData['NHT']
                        ? props.location === 'bina' && (
                            <InfographicKpi2
                                top="313px"
                                right=""
                                bottom=""
                                left="1250px"
                                kpiName="NHT"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['NHT']).toFixed(1)}
                            ></InfographicKpi2>
                        )
                        : props.location === 'bina' && (
                            <InfographicKpi2
                                top="313px"
                                right=""
                                bottom=""
                                left="1040px"
                                kpiName="NHT"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi2>
                        )}

                    <InfographicKpi2
                        top="231px"
                        right=""
                        bottom=""
                        left="1448px"
                        kpiName="CCR"
                        textColor={textColor}
                        kpiValue={utilData['CCR'] ? parseFloat(utilData['CCR']).toFixed(1) : 'N/A'}
                    ></InfographicKpi2>
                    {props.location === 'mumbai' ? (
                        <InfographicKpi2
                            top="312px"
                            right=""
                            bottom=""
                            left="1448px"
                            kpiName="ISOM"
                            textColor={textColor}
                            kpiValue={utilData['ISOM'] ? parseFloat(utilData['ISOM']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    ) : (
                        <InfographicKpi2
                            top="231px"
                            right=""
                            bottom=""
                            left="1244px"
                            kpiName="ISOM"
                            textColor={textColor}
                            kpiValue={utilData['ISOM'] ? parseFloat(utilData['ISOM']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    )}
                    {/* {utilData["KMU"] ? (
            <InfographicKpi2
              top="386px"
              right="1050px"
              bottom=""
              left=""
              kpiName="KMU"
              textColor={textColor}
              kpiValue={parseInt(utilData["KMU"])}
            ></InfographicKpi2>
          ) : (
            <InfographicKpi2
              top="386px"
              right="1050px"
              bottom=""
              left=""
              kpiName="KMU"
              textColor={textColor}
              kpiValue="N/A"
            ></InfographicKpi2>
          )} */}

                    {props.location === 'kochi' && (
                        <InfographicKpi2
                            top="386px"
                            right="846px"
                            bottom=""
                            left=""
                            kpiName="KHDS"
                            textColor={textColor}
                            kpiValue={utilData['KHDS'] ? parseFloat(utilData['KHDS']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    )}
                    {props.location === 'bina' && (
                        <InfographicKpi2
                            top="386px"
                            right="846px"
                            bottom=""
                            left=""
                            kpiName="KHDS"
                            textColor={textColor}
                            kpiValue={utilData['KHDS'] ? parseFloat(utilData['KHDS']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    )}
                    {utilData['ATF']
                        ? props.location === 'bina' && (
                            <InfographicKpi2
                                top="475px"
                                right=""
                                bottom=""
                                left="2175px"
                                kpiName="ATF"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['ATF']).toFixed(1)}
                            ></InfographicKpi2>
                        )
                        : props.location === 'bina' && (
                            <InfographicKpi2
                                top="475px"
                                right=""
                                bottom=""
                                left="2175px"
                                kpiName="ATF"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi2>
                        )}
                    {utilData['DHDS']
                        ? props.location === 'mumbai' && (
                            <InfographicKpi2
                            top="548px"
                            right=""
                            bottom=""
                            left="1217px"
                            kpiName="DHDS"
                            textColor={textColor}
                            kpiValue={utilData['DHDS'] ? parseFloat(utilData['DHDS']).toFixed(1) : 'N/A'}
                            ></InfographicKpi2>
                        )
                        : props.location === 'mumbai' && (
                            <InfographicKpi2
                            top="548px"
                            right=""
                            bottom=""
                            left="1217px"
                            kpiName="DHDS"
                            textColor={textColor}
                            kpiValue={utilData['DHDS'] ? parseFloat(utilData['DHDS']).toFixed(1) : 'N/A'}
                            ></InfographicKpi2>
                        )
                    }
                    {utilData['DHDS']
                        ? props.location === 'kochi' && (
                            <InfographicKpi2
                            top="548px"
                            right=""
                            bottom=""
                            left="1217px"
                            kpiName="DHDS"
                            textColor={textColor}
                            kpiValue={utilData['DHDS'] ? parseFloat(utilData['DHDS']).toFixed(1) : 'N/A'}
                            ></InfographicKpi2>
                        )
                        : props.location === 'kochi' && (
                            <InfographicKpi2
                            top="548px"
                            right=""
                            bottom=""
                            left="1217px"
                            kpiName="DHDS"
                            textColor={textColor}
                            kpiValue={utilData['DHDS'] ? parseFloat(utilData['DHDS']).toFixed(1) : 'N/A'}
                            ></InfographicKpi2>
                        )
                    }


                    {props.location === 'mumbai' &&
                        (utilData['DHT'] ? (
                            <InfographicKpi2
                                top="548px"
                                right=""
                                bottom=""
                                left="1417px"
                                kpiName="DHT"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['DHT']).toFixed(1)}
                            ></InfographicKpi2>
                        ) : (
                            <InfographicKpi2
                                top="548px"
                                right=""
                                bottom=""
                                left="1417px"
                                kpiName="DHT"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi2>
                        ))}
                        {props.location === 'bina' &&
                        (utilData['DHT'] ? (
                            <InfographicKpi2
                                top="548px"
                                right=""
                                bottom=""
                                left="1417px"
                                kpiName="DHT"
                                textColor={textColor}
                                kpiValue={parseFloat(utilData['DHT']).toFixed(1)}
                            ></InfographicKpi2>
                        ) : (
                            <InfographicKpi2
                                top="548px"
                                right=""
                                bottom=""
                                left="1417px"
                                kpiName="DHT"
                                textColor={textColor}
                                kpiValue="N/A"
                            ></InfographicKpi2>
                        ))}
                    {props.location === 'mumbai' && (
                        <InfographicKpi2
                            top=""
                            right=""
                            bottom="465px"
                            left="1496px"
                            kpiName="HCU"
                            textColor={textColor}
                            kpiValue={utilData['HCU'] ? parseFloat(utilData['HCU']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    )}
                    {props.location === 'bina' && (
                        <InfographicKpi2
                            top=""
                            right=""
                            bottom="630px"
                            left="1800px"
                            kpiName="HCU"
                            textColor={textColor}
                            kpiValue={utilData['HCU'] ? parseFloat(utilData['HCU']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    )}
                    {props.location === 'bina' && (
                        <InfographicKpi2
                            top=""
                            right=""
                            bottom="730px"
                            left="1610px"
                            kpiName="SRU"
                            textColor={textColor}
                            kpiValue={utilData['SRU'] ? parseFloat(utilData['SRU']).toFixed(1) : 'N/A'}
                        ></InfographicKpi2>
                    )}
                     {props.location === 'bina' && (
                                    <InfographicKpi2
                                    top=""
                                        right=""
                                        bottom="117px"
                                        left="1620px"
                                        kpiName="DCU"
                                        textColor={textColor}
                                    kpiValue={utilData['DCU'] ? parseFloat(utilData['DCU']).toFixed(1) : 'N/A'}
                                ></InfographicKpi2>
                                )}
                    {props.location === 'mumbai' ? (
                        <div>
                            <InfographicKpi2
                                top=""
                                right="700px"
                                bottom="710px"
                                left=""
                                kpiName="FCCU"
                                textColor={textColor}
                                kpiValue={utilData['FCCU'] ? parseFloat(utilData['FCCU']).toFixed(1) : 'N/A'}
                            ></InfographicKpi2>
                            <InfographicKpi2
                                top=""
                                right="700px"
                                bottom="650px"
                                left=""
                                kpiName="CCU"
                                textColor={textColor}
                                kpiValue={utilData['CCU'] ? parseFloat(utilData['CCU']).toFixed(1) : 'N/A'}
                            ></InfographicKpi2>
                            <InfographicKpi2
                                top=""
                                right="700px"
                                bottom="520px"
                                left=""
                                kpiName="GTU"
                                textColor={textColor}
                                kpiValue={utilData['GTU'] ? parseFloat(utilData['GTU']).toFixed(1) : ''}
                            ></InfographicKpi2>
                        </div>
                    ) : (
                        props.location === 'kochi' && (
                            <div>
                                {/* {utilData["FCCU"] ? (
                  <InfographicKpi2
                    top=""
                    right="720px"
                    bottom="650px"
                    left=""
                    kpiName="FCCU"
                    textColor={textColor}
                    kpiValue={parseInt(utilData["FFCU"])}
                  ></InfographicKpi2>
                ) : (
                  <InfographicKpi2
                    top=""
                    right="720px"
                    bottom="650px"
                    left=""
                    kpiName="FCCU"
                    textColor={textColor}
                    kpiValue="N/A"
                  ></InfographicKpi2>
                )} */}
                                {utilData['VGO HDT'] ? (
                                    <InfographicKpi2
                                        top=""
                                        right=""
                                        bottom="370px"
                                        left="1500px"
                                        kpiName="VGO HDT"
                                        textColor={textColor}
                                        kpiValue={parseFloat(utilData['VGO HDT']).toFixed(1)}
                                    ></InfographicKpi2>
                                ) : (
                                    <InfographicKpi2
                                        top=""
                                        right=""
                                        bottom="370px"
                                        left="1500px"
                                        kpiName="VGO HDT"
                                        textColor={textColor}
                                        kpiValue="N/A"
                                    ></InfographicKpi2>
                                )}
                            </div>
                        )
                    )}
                    {/* {utilData["BBU"] ? (
            <InfographicKpi2
              top=""
              right="1020px"
              bottom="360px"
              left=""
              kpiName="BBU"
              textColor={textColor}
              kpiValue={parseInt(utilData["BBU"])}
            ></InfographicKpi2>
          ) : (
            <InfographicKpi2
              top=""
              right="1020px"
              bottom="360px"
              left=""
              kpiName="BBU"
              textColor={textColor}
              kpiValue="N/A"
            ></InfographicKpi2>
          )} */}

                    {inventoryData['LPG'] ? (
                        <InfographicKpi1
                            top="146px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="LPG"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['LPG']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : (
                        <InfographicKpi1
                            top="146px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="LPG"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpi1>
                    )}

                    {inventoryData['MS'] ? (
                        <InfographicKpi1
                            top="266px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="MS"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['MS']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : (
                        <InfographicKpi1
                            top="266px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="MS"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpi1>
                    )}

                    {inventoryData['ATF'] ? (
                        <InfographicKpi1
                            top="376px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="ATF"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['ATF']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : (
                        <InfographicKpi1
                            top="376px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="ATF"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpi1>
                    )}

                    {inventoryData['Diesel'] ? (
                        <InfographicKpi1
                            top="600px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Diesel"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['Diesel']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : (
                        <InfographicKpi1
                            top="600px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Diesel"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpi1>
                    )}

                    {inventoryData['Propylene'] ? (
                        <InfographicKpi1
                            top="796px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Propylene"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['Propylene']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : props.location === 'bina' && ( 
                        <div></div>
                    )}
                    {inventoryData['Propylene'] ? (
                        <InfographicKpi1
                            top="796px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Propylene"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['Propylene']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : props.location === 'mumbai' && ( 
                        <InfographicKpi1
                            top="796px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Propylene"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpi1>
                    )}
                    {inventoryData['Propylene'] ? (
                        <InfographicKpi1
                            top="796px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Propylene"
                            textColor={textColor}
                            kpiValue={Number(inventoryData['Propylene']).toFixed(1)}
                        ></InfographicKpi1>
                    ) : props.location === 'kochi' && ( 
                        <InfographicKpi1
                            top="796px"
                            right="210px"
                            bottom=""
                            left=""
                            kpiName="Propylene"
                            textColor={textColor}
                            kpiValue="N/A"
                        ></InfographicKpi1>
                    )}
                    {inventoryData['Lobs']
                        ? props.location === 'mumbai' && (
                            <InfographicKpi1
                                top="964px"
                                right="210px"
                                bottom=""
                                left=""
                                textColor={textColor}
                                kpiName="Lobs"
                                kpiValue={Number(inventoryData['Lobs']).toFixed(1)}
                            ></InfographicKpi1>
                        )
                        : props.location === 'mumbai' && (
                            <InfographicKpi1
                                top="964px"
                                right="210px"
                                bottom=""
                                left=""
                                textColor={textColor}
                                kpiName="Lobs"
                                kpiValue="N/A"
                            ></InfographicKpi1>
                        )}

                    {/* {utilData["VDU 3"]
            ? props.location === "kochi" && (
                <InfographicKpi1
                  top=""
                  right=""
                  bottom="315px"
                  left="790px"
                  kpiName="VDU 3"
                  textColor={textColor}
                  kpiValue={parseInt(utilData["VDU 3"])}
                ></InfographicKpi1>
              )
            : props.location === "kochi" && (
                <InfographicKpi1
                  top=""
                  right=""
                  bottom="315px"
                  left="790px"
                  kpiName="VDU 3"
                  textColor={textColor}
                  kpiValue="N/A"
                ></InfographicKpi1>
              )} */}
                    {/* {utilData["VDU 4"]
            ? props.location === "kochi" && (
                <InfographicKpi1
                  top=""
                  right=""
                  bottom="250px"
                  left="790px"
                  kpiName="VDU 4"
                  textColor={textColor}
                  kpiValue={parseInt(utilData["VDU 4"])}
                ></InfographicKpi1>
              )
            : props.location === "kochi" && (
                <InfographicKpi1
                  top=""
                  right=""
                  bottom="250px"
                  left="790px"
                  kpiName="VDU 4"
                  textColor={textColor}
                  kpiValue="N/A"
                ></InfographicKpi1>
              )} */}
                </>
            )}
            {crudeProcMtdData['LS'] && crudeProcMtdData['deltaMtdLS'] ? (
                <InfographicKpi5
                    top="450px"
                    right=""
                    bottom=""
                    left="195px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['LS'] ? parseInt(crudeProcYtmData['LS']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['LS'] ? parseInt(crudeProcMtdData['LS']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmLS'] ? parseFloat(crudeProcYtmData['deltaYtmLS']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdLS'] ? parseFloat(crudeProcMtdData['deltaMtdLS']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="450px"
                    right=""
                    bottom=""
                    left="195px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/AA"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            )}

{crudeProcYtmData['LS'] && crudeProcYtmData['deltaYtmLS'] ? (
                <InfographicKpi5
                    top="450px"
                    right=""
                    bottom=""
                    left="195px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['LS'] ? parseInt(crudeProcYtmData['LS']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['LS'] ? parseInt(crudeProcMtdData['LS']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmLS'] ? parseFloat(crudeProcYtmData['deltaYtmLS']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdLS'] ? parseFloat(crudeProcMtdData['deltaMtdLS']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="450px"
                    right=""
                    bottom=""
                    left="195px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdLS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            )}
            {crudeProcMtdData['HS'] && crudeProcMtdData['deltaMtdHS'] ? (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="15px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['HS'] ? parseInt(crudeProcYtmData['HS']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['HS'] ? parseInt(crudeProcMtdData['HS']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmHS'] ? parseFloat(crudeProcYtmData['deltaYtmHS']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdHS'] ? parseFloat(crudeProcMtdData['deltaMtdHS']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="15px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            )}

{crudeProcYtmData['HS'] && crudeProcYtmData['deltaYtmHS'] ? (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="15px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['HS'] ? parseInt(crudeProcYtmData['HS']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['HS'] ? parseInt(crudeProcMtdData['HS']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmHS'] ? parseFloat(crudeProcYtmData['deltaYtmHS']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdHS'] ? parseFloat(crudeProcMtdData['deltaMtdHS']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="15px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdHS']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            )}

            {crudeProcMtdData['Indigenous(MH)'] && crudeProcMtdData['deltaMtdIndigenous(MH)'] ? (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="375px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['Indigenous(MH)'] ? parseInt(crudeProcYtmData['Indigenous(MH)']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['Indigenous(MH)'] ? parseInt(crudeProcMtdData['Indigenous(MH)']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmIndigenous(MH)'] ? parseFloat(crudeProcYtmData['deltaYtmIndigenous(MH)']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdIndigenous(MH)'] ? parseFloat(crudeProcMtdData['deltaMtdIndigenous(MH)']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="375px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            )}


{crudeProcYtmData['Indigenous(MH)'] && crudeProcYtmData['deltaYtmIndigenous(MH)'] ? (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="375px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['Indigenous(MH)'] ? parseInt(crudeProcYtmData['Indigenous(MH)']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['Indigenous(MH)'] ? parseInt(crudeProcMtdData['Indigenous(MH)']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmIndigenous(MH)'] ? parseFloat(crudeProcYtmData['deltaYtmIndigenous(MH)']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdIndigenous(MH)'] ? parseFloat(crudeProcMtdData['deltaMtdIndigenous(MH)']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="650px"
                    right=""
                    bottom=""
                    left="375px"
                    height="70px"
                    width="260px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdIndigenous(MH)']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD"
                    tableheadmtd="MTD"
                ></InfographicKpi5>
            )}

            {crudeProcMtdData['Total Crude'] && crudeProcMtdData['deltaMtdTotal Crude'] ? (
                <InfographicKpi5
                    top="158px"
                    right=""
                    bottom=""
                    left="179px"
                    height="70px"
                    width="389px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['Total Crude'] ? parseInt(crudeProcYtmData['Total Crude']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['Total Crude'] ? parseInt(crudeProcMtdData['Total Crude']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmTotal Crude'] ? parseFloat(crudeProcYtmData['deltaYtmTotal Crude']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdTotal Crude'] ? parseFloat(crudeProcMtdData['deltaMtdTotal Crude']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD (TMT)"
                    tableheadmtd="MTD (TMT)"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="158px"
                    right=""
                    bottom=""
                    left="179px"
                    height="70px"
                    width="389px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD (MMTPA)"
                    tableheadmtd="MTD (TMT)"
                ></InfographicKpi5>
            )}
             {crudeProcYtmData['Total Crude'] && crudeProcYtmData['deltaYtmTotal Crude'] ? (
                <InfographicKpi5
                    top="158px"
                    right=""
                    bottom=""
                    left="179px"
                    height="70px"
                    width="389px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData={crudeProcYtmData['Total Crude'] ? parseInt(crudeProcYtmData['Total Crude']) : 'N/A'}
                    crudeProcMtdData={crudeProcMtdData['Total Crude'] ? parseInt(crudeProcMtdData['Total Crude']) : 'N/A'}
                    crudeProcYtmDelta={crudeProcYtmData['deltaYtmTotal Crude'] ? parseFloat(crudeProcYtmData['deltaYtmTotal Crude']).toFixed(1) : 'N/A'}
                    crudeProcMtdDelta={crudeProcMtdData['deltaMtdTotal Crude'] ? parseFloat(crudeProcMtdData['deltaMtdTotal Crude']).toFixed(1) : 'N/A'}
                    tableheadytm="YTD (TMT)"
                    tableheadmtd="MTD (TMT)"
                ></InfographicKpi5>
            ) : (
                <InfographicKpi5
                    top="158px"
                    right=""
                    bottom=""
                    left="179px"
                    height="70px"
                    width="389px"
                    textColor={textColor}
                    crudeProcYtmColor={
                        Math.sign(crudeProcYtmData['deltaYtmTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcMtdColor={
                        Math.sign(crudeProcMtdData['deltaMtdTotal Crude']) === 1
                            ? '#74ce12'
                            : '#e13219'
                    }
                    crudeProcYtmData="N/A"
                    crudeProcMtdData="N/A"
                    crudeProcYtmDelta="N/A"
                    crudeProcMtdDelta="N/A"
                    tableheadytm="YTD (MMTPA)"
                    tableheadmtd="MTD (TMT)"
                ></InfographicKpi5>
            )}
        </div>
    )
}
export default RefinaryInfographic
