import axios from "axios";
import {
  API_BASE_URL,
  API_ROUTE,
  ENCRYPTION_KEYS,
} from "../utils/constants/constants";
import { decryptData, encryptData } from "../utils/crypto-utils";

const apiClient = axios.create({
  baseURL: API_BASE_URL,
});
const REACT_APP_ENVIRONMENT = "true";
const setupInterceptors = () => {
  apiClient.interceptors.request.use(
    (config) => {
      const splitUrl = config.url.split("/");
      if (
        splitUrl &&
        config.data &&
        !config.headers["encryption"] &&
        REACT_APP_ENVIRONMENT === "true"
      ) {
        config.headers["encryption"] = ENCRYPTION_KEYS.TRUE_ENC;
        const encryptedData = encryptData(config.data);
        config.data = { data: encryptedData };
      }
      config.headers["x-access-token"] = localStorage.getItem("token");

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  apiClient.interceptors.response.use(
    (response) => {
      const splitUrl = response.config.url.split("/");
      if (
        splitUrl &&
        response.data?.data &&
        typeof response.data.data !== "object" &&
        !splitUrl.includes("upload") &&
        REACT_APP_ENVIRONMENT === "true"
      ) {
        const decryptedData = decryptData(response.data.data);
        response.data.data = decryptedData;
      }
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem("token");
        window.location.href = "/login";
      }
      return Promise.reject(error);
    }
  );
};
setupInterceptors();
const loginUser = async (email, password) => {
  return apiClient.post(API_ROUTE.LOGIN.LOGIN_USER, { email, password });
};
const checkinVisitors = async (payload) => {
  return apiClient.post(API_ROUTE.CHECKIN.CHECKIN_VISITORS, payload);
};
const getUserOfEvent = async (payload) => {
  return apiClient.post(API_ROUTE.CHECKIN.EVENT_LIST, payload);
};
const getUserOfEventExport = async (payload) => {
  return apiClient.post(API_ROUTE.CHECKIN.EVENT_EXPORT, payload);
};
const getWinnerData = async (payload) => {
  return apiClient.post(API_ROUTE.CHECKIN.WINNER_LIST, payload);
};

export {
  checkinVisitors,
  getUserOfEvent,
  getUserOfEventExport,
  getWinnerData,
  loginUser,
};
