import CryptoJS from "crypto-js";
import { ENCRYPTION_KEYS } from "./constants/constants";

const secretKey = ENCRYPTION_KEYS.SECRET_KEY; // Replace with your actual secret key

export const encryptData = (data) => {
  return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
};

export const decryptData = (ciphertext) => {
  const bytes = CryptoJS.AES.decrypt(ciphertext, secretKey);
  return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};
