import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { loginUser } from "../services/qrService";
import "../utils/common/qr.scss";

const EventLogin = () => {
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();

  const enhancedEmailValidationRegex =
    /^(?!.*\.\.)(?!.*[!#$%^&*(),?":{}|<>])([a-zA-Z0-9._%+-]+)@([a-zA-Z0-9-]+\.[a-zA-Z]{2,})$/;

  const validationSchema = Yup.object({
    email: Yup.string()
      .matches(enhancedEmailValidationRegex, "Email format is invalid")
      .test("no-leading-hyphen", "Email format is invalid", (value) => {
        if (value) {
          const domain = value.split("@")[1];
          return domain && !domain.startsWith("-");
        }
        return true;
      })
      .max(50, "Email address should not exceed 50 characters")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const handleLogin = async (values, actions) => {
    try {
      const response = await loginUser(values.email, values.password);
      if (response.status === 200) {
        if (response?.data?.data?.is_deleted) {
          setErrorMessage(response?.data?.message);
        } else if (!response?.data?.data?.is_active) {
          setErrorMessage(response?.data?.message);
        } else {
          localStorage.setItem("token", response.data.data.token);
          localStorage.setItem("userId", response.data.data.user_id);
          localStorage.setItem("userName", response.data.data.first_name);
          localStorage.setItem("email", response.data.data.email);
          history.push("/event/qr-scanner");
        }
      } else {
        setErrorMessage("Username and password do not match");
      }
    } catch (error) {
      setErrorMessage("Username and password do not match");
    }
  };

  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-page">
      <div className="login-wrapper">
        <Formik
          initialValues={{
            email: "",
            password: "",
          }}
          validationSchema={validationSchema}
          onSubmit={handleLogin}
        >
          {({ isSubmitting }) => (
            <Form>
              <h1>Login</h1>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <>
                <div className="input-box">
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    className="form-input"
                  />
                  <ErrorMessage
                    name="email"
                    component="p"
                    className="error-message"
                  />
                </div>
                <div className="input-box">
                  <Field
                    type={showPassword ? "text" : "password"}
                    name="password"
                    placeholder="Password"
                    className="form-input"
                  />
                  <span
                    onClick={togglePasswordVisibility}
                    className="password-toggle-icon"
                  >
                    {showPassword ? (
                      <HiEyeOff color="#000000" />
                    ) : (
                      <HiEye color="#000000" />
                    )}
                  </span>
                  <ErrorMessage
                    name="password"
                    component="p"
                    className="error-message"
                  />
                </div>
                <button
                  className="btn text-white"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Login
                </button>
              </>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EventLogin;
