import { BU_KPI_URL, ORG_KPI_URL, PLANNING_KPI_URL } from '../environment'
import axios from 'axios'
import { withAuthorizationHeaders } from '../components/Authentication/services/authHeaders'

export const fetchMenuData = async (year) => {
    let url = `${BU_KPI_URL}/bumenutab/data?data_type=ytm&kpi_name=butotal&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfCustomerGrowth = async (year) => {
    let url = `${BU_KPI_URL}/key_customer/data?data_type=ytm&level=country&business_unit=atf&kpi_type=growth&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchAtfCustomerTrend = async (year) => {
    let url = `${BU_KPI_URL}/key_customer/data?data_type=ytm&level=country&kpi_type=trends&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfHsse = async (year) => {
    let url = `${BU_KPI_URL}/others/data?data_type=ytm&level=country&business_unit=atf&kpi_type=hsse&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfLossGain = async (year) => {
    let url = `${BU_KPI_URL}/loss_gain/data?data_type=ytm&level=country&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfMarketShare = async (year) => {
    let url = `${BU_KPI_URL}/market/data?data_type=ytm&level=country&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfOperatingCost = async (year) => {
    let url = `${BU_KPI_URL}/operating/data?data_type=ytm&level=country&business_unit=atf&kpi_name=operating cost&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfOhers = async (kpiName, year) => {
    let url = `${BU_KPI_URL}/payment/data?level=country&data_type=ytm&kpi_name=${kpiName}&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfProjects = async (year) => {
    const urlByYear = `${BU_KPI_URL}/projects?business_unit=atf&year=${year}`
    const res = await axios.get(
        urlByYear,
        { withCredentials: true }
    )
    return res.data
}

export const fetchAtfSalesRevenue = async (kpiName, year) => {
    let url = `${BU_KPI_URL}/data?data_type=ytm&level=country&business_unit=atf&kpi_name=${kpiName}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfTankInventory = async (year) => {
    let url = `${BU_KPI_URL}/inventory/data?data_type=ytd&level=country&kpi_name=inventory&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=region&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfStateMap = async (region, year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=atf&region=${region}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfMaptable = async (activeKpi, level, appendRegion, year) => {
    const url = `${BU_KPI_URL}/table_overall/data?data_type=ytm&level=${level}&business_unit=atf${appendRegion}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncHsse = async (year) => {
    let url = `${BU_KPI_URL}/others/data?data_type=ytm&level=country&business_unit=inc&kpi_type=hsse&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncInventory = async (year) => {
    let url = `${BU_KPI_URL}/incmou/data?bu=inc&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

// export const fetchIndentaionRatio = async (depotType) => {
//     let url = `${BU_KPI_URL}/indendation_ratio/data?data_type=ytm&level=country&business_unit=inc&depot_type=${depotType}`
//     const res = await axios.get(url, {
//         headers: withAuthorizationHeaders(),
//     })
//     return res.data
// }

export const fetchIncLossGain = async (year) => {
    let url = `${BU_KPI_URL}/loss_gain/data?data_type=ytm&level=country&business_unit=inc&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncInventoryGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=region&business_unit=inc&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncInventoryStateMap = async (region,year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=inc&region=${region}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncInventoryMapTable = async (kpiName, level, appendRegion, year) => {
    const url = `${BU_KPI_URL}/table_overall/data?data_type=ytm&level=${level}&business_unit=inc${appendRegion}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgCcs = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=lpg&kpi_name=ccs&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgCommercialDiscount = async (kpiName, product, year) => {
    let url = `${BU_KPI_URL}/data?business_unit=lpg&kpi_name=${kpiName}&data_type=ytm&level=country&product=${product}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgCustomerPopulation = async (year) => {
    let url = `${BU_KPI_URL}/customer_population/data?business_unit=lpg&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgDepotInventory = async (year) => {
    let url = `${BU_KPI_URL}/inventory/data?business_unit=lpg&kpi_name=inventory&data_type=ytd&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgHsse = async (year) => {
    let url = `${BU_KPI_URL}/others/data?data_type=ytm&level=country&business_unit=lpg&kpi_type=hsse&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgMarketShare = async (product, year) => {
    let url = `${BU_KPI_URL}/market/data?data_type=ytm&level=country&business_unit=lpg&product=${product}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgOperatingCost = async (year) => {
    let url = `${BU_KPI_URL}/operating/data?business_unit=lpg&kpi_name=operating cost&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgOthers = async (year) => {
    let url = `${BU_KPI_URL}/others/data?data_type=ytm&level=country&business_unit=lpg&kpi_type=others&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgProjects = async (year) => {
    const urlByYear = year >= '2023' ? `${BU_KPI_URL}/projects?business_unit=lpg&data_type=ytm&year=${year}` : `${BU_KPI_URL}/projects?business_unit=lpg&year=${year}`
    const res = await axios.get(
        urlByYear,
        { withCredentials: true }
    )
    return res.data
}

export const fetchLpgSalesRevenue = async (kpiName, product, year) => {
    let url = `${BU_KPI_URL}/data?business_unit=lpg&kpi_name=${kpiName}&data_type=ytm&level=country&product=${product}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=region&business_unit=lpg&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgStateMap = async (region,year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=lpg&region=${region}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgMaptable = async (kpiName, level, appendRegion, year) => {
    let url
    if (kpiName === 'all') {
        url = `${BU_KPI_URL}/table_overall/data?data_type=ytm&level=${level}&business_unit=lpg${appendRegion}&year=${year}`
    } else {
        url = `${BU_KPI_URL}/table_detail/data?data_type=ytm&level=country&kpi_name=${kpiName}&business_unit=retail&year=${year}`
    }
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesDiscount = async (kpiName, year) => {
    let url = `${BU_KPI_URL}/discount_sales/data?level=country&data_type=ytm&business_unit=lubes&kpi_name=${kpiName}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesHsse = async (year) => {
    let url = `${BU_KPI_URL}/others/data?level=country&data_type=ytm&business_unit=lubes&kpi_type=hsse&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesInventory = async (year) => {
    let url = `${BU_KPI_URL}/inventory/data?level=country&business_unit=lubes&kpi_name=inventory&data_type=ytd&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesLossGain = async (year) => {
    let url = `${BU_KPI_URL}/loss_gain/data?level=country&data_type=ytm&business_unit=lubes&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesMarketShare = async (year) => {
    let url = `${BU_KPI_URL}/market/data?data_type=ytm&level=country&business_unit=lubes&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesOperatingCost = async (year) => {
    let url = `${BU_KPI_URL}/operating/data?business_unit=lubes&kpi_name=operating cost&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesOthers = async (kpiName, year) => {
    let url = `${BU_KPI_URL}/payment/data?level=country&data_type=ytm&kpi_name=${kpiName}&business_unit=lubes&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesSalesRevenue = async (kpiName, year) => {
    let url = `${BU_KPI_URL}/data?business_unit=lubes&kpi_name=${kpiName}&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=region&business_unit=lubes&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesStateMap = async (region, year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=lubes&region=${region}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesMapTable = async (kpiName, level, appendRegion, year) => {
    let url
    if (kpiName === 'all') {
        url = `${BU_KPI_URL}/table_overall/data?level=${level}&data_type=ytm&business_unit=lubes${appendRegion}&year=${year}`
    } else {
        url = `${BU_KPI_URL}/table_detail/data?data_type=ytm&business_unit=lubes&level=country&kpi_name=${kpiName}&year=${year}`
    }
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchLubesCcsOpen = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=lubes&kpi_name=ccs&data_type=ytm&level=country&resolve_status=N&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLubesCcsResolved = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=lubes&kpi_name=ccs&data_type=ytm&level=country&resolve_status=Y&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailCcsOpen = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=retail&kpi_name=ccs&data_type=ytm&level=country&resolve_status=N&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailCcsResolved = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=retail&kpi_name=ccs&data_type=ytm&level=country&resolve_status=Y&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchLpgCcsOpen = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=lpg&kpi_name=ccs&data_type=ytm&level=country&resolve_status=N&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLpgCcsResolved = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=lpg&kpi_name=ccs&data_type=ytm&level=country&resolve_status=Y&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchAtfCcs = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?data_type=ytm&level=country&business_unit=atf&kpi_name=ccs&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailDepotInventory = async (year) => {
    let url = `${BU_KPI_URL}/inventory/data?business_unit=retail&kpi_name=inventory&data_type=ytd&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailEthanolBlending = async (kpiType, year) => {
    let url = `${BU_KPI_URL}/data?business_unit=retail&kpi_name=ethanol blending&data_type=ytm&level=country&kpi_type=${kpiType}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailHsse = async (year) => {
    let url = `${BU_KPI_URL}/others/data?business_unit=retail&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailMarketShare = async (product, year) => {
    let url = `${BU_KPI_URL}/market/data?business_unit=retail&data_type=ytm&level=country&year=${year}&product=${product}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBuIncCountry = async (year) => {
    let url = `${BU_KPI_URL}/market/data?data_type=ytm&level=country&business_unit=inc&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBuIncOutstandingPayment = async (year) => {
    let url = `${BU_KPI_URL}/payment/data?data_type=ytm&level=country&business_unit=inc&kpi_name=outstanding payment&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBuIncOutstandingPaymentTerritory = async (year) => {
    let url = `${BU_KPI_URL}/payment/data?data_type=ytm&level=country&business_unit=inc&kpi_name=outstanding territory wise&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

// export const fetchProfitablityAnalysis = async (year) => {
//     let url = `${BU_KPI_URL}/others/data?data_type=ytm&level=country&business_unit=inc&kpi_name=profitability analysis`
//     const res = await axios.get(url, {
//         headers: withAuthorizationHeaders(),
//     })
//     return res.data
// }

export const fetchIncSalesRevenue = async (kpiName, year) => {
    let url = `${BU_KPI_URL}/data?data_type=ytm&level=country&business_unit=inc&kpi_name=${kpiName}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailOperatingCost = async (year) => {
    let url = `${BU_KPI_URL}/operating/data?business_unit=retail&kpi_name=operating cost&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncOperatingCost = async (year) => {
    let url = `${BU_KPI_URL}/operating/data?business_unit=inc&kpi_name=operating cost&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailProjects = async (year) => {
    const urlByYear = year >= '2023' ? `${BU_KPI_URL}/projects?business_unit=retail&data_type=ytm&year=${year}` : `${BU_KPI_URL}/projects?business_unit=retail&year=${year}`
    const res = await axios.get(
        urlByYear,
        { withCredentials: true }
    )
    return res.data
}

export const fetchRetailOutlets = async (year) => {
    let url = `${BU_KPI_URL}/retail_outlet/data?data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailSalesRevenue = async (
    kpiName,
    product,
    year
) => {
    let url = `${BU_KPI_URL}/data?business_unit=retail&kpi_name=${kpiName}&data_type=ytm&level=country&product=${product}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailTransportation = async (year) => {
    let url = `${BU_KPI_URL}/data?business_unit=retail&kpi_name=transportation charge&data_type=ytm&level=country&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=region&business_unit=retail&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchRetailStateGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=retail&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchINCStateGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=inc&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchAtfStateGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=atf&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchLubesStateGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=lubes&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchLPGStateGoogleMap = async (year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=lpg&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchRetailStateMap = async (region, year) => {
    let url = `${BU_KPI_URL}/overall/data?data_type=ytm&level=state&business_unit=retail&region=${region}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchRetailMapTable = async (kpiName, level, appendRegion, year) => {
    let url
    if (kpiName === 'all') {
        url = `${BU_KPI_URL}/table_overall/data?data_type=ytm&level=${level}&business_unit=retail${appendRegion}&year=${year}`
    } else {
        url = `${BU_KPI_URL}/table_detail/data?data_type=ytm&level=country&kpi_name=${kpiName}&business_unit=retail&year=${year}`
    }
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncCcsReceived = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=inc&kpi_name=ccs&data_type=ytm&level=country&resolve_status=N&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchIncCcsResolved = async (year) => {
    let url = `${BU_KPI_URL}/complaints/data?business_unit=inc&kpi_name=ccs&data_type=ytm&level=country&resolve_status=Y&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBuLanding = async (year) => {
    const res = await axios.get(
        `${ORG_KPI_URL}/orgyr`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchProjectsData = async (year) => {
    const urlByYear = `${BU_KPI_URL}/projects/data?data_type=ytm&year=${year}`
    const res = await axios.get(
        urlByYear,
        { withCredentials: true }
    )
    return res.data
}

export const fetchGoogleMap = async (year) => {
    const url = `${BU_KPI_URL}/projects/map?kpi_name=map&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchupdatedStatus = async (year) => {
    let url = `${PLANNING_KPI_URL}/getUpdatedStatus?year=${year}&bu=BUSINESS UNITS`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}