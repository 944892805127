import axios from 'axios'
import { withAuthorizationHeaders } from '../components/Authentication/services/authHeaders'
import { ORG_KPI_URL, REFINERY_KPI_URL, PLANNING_KPI_URL } from '../environment'
axios.defaults.withCredentials = true;
// { withCredentials: true }{ withCredentials: true }
export const fetchKochiUsdDataGRM = async (year) => {
    let url = `${REFINERY_KPI_URL}/grm/year/data?kpi_name=GRM&refinery=Kochi&unit=USD/barrel&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchKochiInrDataGRM = async (year) => {
    let url = `${REFINERY_KPI_URL}/grm/year/data?kpi_name=GRM&refinery=Kochi&unit=INR Crore&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMumbaiUsdDataGRM = async (year) => {
    let url = `${REFINERY_KPI_URL}/grm/year/data?kpi_name=GRM&refinery=Mumbai&unit=USD/barrel&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMumbaiInrDataGRM = async (year) => {
    let url = `${REFINERY_KPI_URL}/grm/year/data?kpi_name=GRM&refinery=Mumbai&unit=INR Crore&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBinaUsdDataGRM = async (year) => {
    let url = `${REFINERY_KPI_URL}/grm/year/data?kpi_name=GRM&refinery=Bina&unit=USD/barrel&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBinaInrDataGRM = async (year) => {
    let url = `${REFINERY_KPI_URL}/grm/year/data?kpi_name=GRM&refinery=Bina&unit=INR Crore&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchKochiUsdDataOperatingCost = async (year) => {
    let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Kochi&unit=USD/barrel&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchKochiInrDataOperatingCost = async (year) => {
    let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Kochi&unit=Rs/MT&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

// export const fetchMumbaiUsdDataOperatingCost = async (year) => {
//     let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Mumbai&unit=USD/barrel&year=${year}`
//     const res = await axios.get(url, { withCredentials: true, credentials: 'include' })
//     return res.data
// }
export const fetchMumbaiUsdDataOperatingCost = async (year) => {
    let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Mumbai&unit=USD/barrel&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchMumbaiInrDataOperatingCost = async (year) => {
    let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Mumbai&unit=Rs/MT&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBinaUsdDataOperatingCost = async (year) => {
    let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Bina&unit=USD/barrel&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBinaInrDataOperatingCost = async (year) => {
    let url = `${REFINERY_KPI_URL}/operatingCost/year/data?kpi_name=operating cost&refinery=Bina&unit=Rs/MT&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchEnergyIntensityIndex = async (year, refinery) => {
    let url = `${REFINERY_KPI_URL}/eii/year/data?kpi_name=Energy intensity index&refinery=${refinery}&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchDistillateYieldMumbaiMtd = async (year) => {
    let url = `${REFINERY_KPI_URL}/distillateYield/day/data?kpi_name=Distillate yield&refinery=Mumbai&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchDistillateYieldMumbaiYtm = async (year) => {
    let url = `${REFINERY_KPI_URL}/distillateYield/month/data?kpi_name=Distillate yield&refinery=Mumbai&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchDistillateYieldKochiYtm = async (year) => {
    let url = `${REFINERY_KPI_URL}/distillateYield/month/data?kpi_name=Distillate yield&refinery=Kochi&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchDistillateYieldkochiMtd = async (year) => {
    let url = `${REFINERY_KPI_URL}/distillateYield/day/data?kpi_name=Distillate yield&refinery=Kochi&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchDistillateYieldBinaMtd = async (year) => {
    let url = `${REFINERY_KPI_URL}/distillateYield/day/data?kpi_name=Distillate yield&refinery=Bina&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchDistillateYieldBinaYtm = async (year) => {
    let url = `${REFINERY_KPI_URL}/distillateYield/month/data?kpi_name=Distillate yield&refinery=Bina&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
export const fetchKochiYtmDataProduction = async (year) => {
    let url = `${REFINERY_KPI_URL}/production/month/data?kpi_name=Production&refinery=Kochi&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchKochiMtdDataProduction = async (year) => {
    let url = `${REFINERY_KPI_URL}/production/day/data?kpi_name=Production&refinery=Kochi&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMumbaiYtmDataProduction = async (year) => {
    let url = `${REFINERY_KPI_URL}/production/month/data?kpi_name=Production&refinery=Mumbai&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchMumbaiMtdDataProduction = async (year) => {
    let url = `${REFINERY_KPI_URL}/production/day/data?kpi_name=Production&refinery=Mumbai&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBinaYtmDataProduction = async (year) => {
    let url = `${REFINERY_KPI_URL}/production/month/data?kpi_name=Production&refinery=Bina&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchBinaMtdDataProduction = async (year) => {
    let url = `${REFINERY_KPI_URL}/production/day/data?kpi_name=Production&refinery=Bina&year=${year}`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}

export const fetchTotalCrudeKochiMtdCrudeOilInventory = async (year, refinery) => {
    const res = await axios.get(
        `${REFINERY_KPI_URL}/crudeOilInventory/day/data?kpi_name=Crude Oil Inventory&refinery=${refinery}&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchTotalCrudeKochiYtmCrudeOilInventory = async (year, refinery) => {
    const res = await axios.get(
        `${REFINERY_KPI_URL}/crudeOilInventory/month/data?kpi_name=Crude Oil Inventory&refinery=${refinery}&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchMumbaiUnitWiseUtil = async (year, refinery) => {
    const res = await axios.get(
        `${REFINERY_KPI_URL}/unitWise/day/data?kpi_name=Unit wise Utilization&refinery=${refinery}&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchMumbaiProductInv = async (year, refinery) => {
    const res = await axios.get(
        `${REFINERY_KPI_URL}/productInventory/day/data?kpi_name=Product Inventory&refinery=${refinery}&year=${year}`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchRefineryLanding = async () => {
    const res = await axios.get(
        `${ORG_KPI_URL}/orgyr`,
        { withCredentials: true }
    )
    return res.data
}

export const fetchupdatedStatus = async (year) => {
    let url = `${PLANNING_KPI_URL}/getUpdatedStatus?year=${year}&bu=REFINERY`
    const res = await axios.get(url, { withCredentials: true })
    return res.data
}
